import { FormattedMessage, defineMessages } from 'dibs-react-intl';
import { CATEGORY_LEVEL_1 } from '../utils/categoryHelpers';
import { FILTER_NAME_CREATOR } from '../constants/sbConstants';

export const moreWaysToBrowseHeader = (
    <FormattedMessage id="SharedMWTBLinksBlock.header" defaultMessage="More Ways To Browse" />
);

export const iconicDesignsText = (
    <FormattedMessage
        id="SbRespContentModuleIconicDesign.iconicDesignsText"
        defaultMessage="Iconic Designs"
    />
);

export const aboutTheDesignText = (
    <FormattedMessage
        id="SbRespContentModuleIconicDesign.aboutTheDesignText"
        defaultMessage="About the Design"
    />
);

export const creatorNameText = name => (
    <FormattedMessage
        id="SbRespContentModuleIconicDesign.creatorNameText"
        defaultMessage="By {name}"
        values={{
            name,
        }}
    />
);

export const relatedPagesTexts = (appliedFilters, filters = []) => {
    let categoryL1Filter = appliedFilters.find(f => f.name === CATEGORY_LEVEL_1);
    const pageNameFilter = appliedFilters.find(f => f.name === FILTER_NAME_CREATOR);
    let categoryL1 = categoryL1Filter?.values[0]?.displayName;
    const creator = pageNameFilter?.values[0]?.displayName;
    if (!creator) {
        return null;
    } else if (!categoryL1) {
        categoryL1Filter = filters.find(f => f.name === CATEGORY_LEVEL_1);
        categoryL1 = categoryL1Filter?.values[0]?.displayName;
        if (!categoryL1) {
            return null;
        }
    }
    return {
        anchorTextPrefix: creator,
        linksBlockTitle: (
            <FormattedMessage
                id="SharedRelatedPages.title"
                defaultMessage="More {creator} {categoryL1}"
                values={{ creator, categoryL1 }}
            />
        ),
    };
};

export const readMore = (
    <FormattedMessage defaultMessage="Read More" id="sb.sharedContentModuleIconicDesign.readMore" />
);

export const readLess = (
    <FormattedMessage defaultMessage="Read Less" id="sb.sharedContentModuleIconicDesign.readLess" />
);

export const moreCreatorDesigns = creator => (
    <FormattedMessage
        id="sb.SbSharedCreatorDesigns.moreCreatorDesigns"
        defaultMessage="More {creator} Designs"
        values={{ creator }}
    />
);

export const sortOrderLabel = (
    <FormattedMessage id="SbRespRefineTopNav.sortOrderLabel" defaultMessage="Sort By" />
);

export const viewMore = <FormattedMessage id="global.viewMore" defaultMessage="View More" />;

export const viewLess = <FormattedMessage id="global.viewLess" defaultMessage="View Less" />;

export const youMayAlsoLike = (
    <FormattedMessage id="global.youMayAlsoLike" defaultMessage="You May Also Like" />
);

export const recentSales = (
    <FormattedMessage id="global.recentSales" defaultMessage="Recent Sales" />
);

export const peopleAlsoBrowsed = (
    <FormattedMessage id="global.peopleAlsoBrowsed" defaultMessage="People Also Browsed" />
);

export const addToCalendarMessages = defineMessages({
    title: {
        defaultMessage: '1stDibs {eventName} Ends Today',
        id: 'SbSharedAddToCalendar.event.title',
    },
    description: {
        defaultMessage:
            'Auctions start closing at 2pm ET, so please visit {collectionUrl} and place your bids now.',
        id: 'SbSharedAddToCalendar.event.subTitle',
    },
});

export const addToCalendarText = (
    <FormattedMessage
        id="SbSharedAddToCalendar.addToCalendarText"
        defaultMessage="Add To Calendar"
    />
);

export const outlook = <FormattedMessage defaultMessage="Outlook" id="sb.outlook" />;
export const google = <FormattedMessage defaultMessage="Google" id="sb.google" />;
export const apple = <FormattedMessage defaultMessage="Apple" id="sb.apple" />;
