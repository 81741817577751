import { FC, useCallback, useMemo } from 'react';
import { graphql, useFragment, useRefetchableFragment } from 'react-relay';
import { Heart } from 'dibs-portfolio-heart/exports/Heart';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { useRecentlyViewedItemsContext } from './RecentlyViewedItemsContext';
import { trackHeart } from '../recentHistoryTracking';
import { useRecentHistoryList } from './useRecentHistoryList';

import { ItemType } from '../recentHistoryTypes';
import { RecentlyViewedItemsHeart_viewer$key } from './__generated__/RecentlyViewedItemsHeart_viewer.graphql';
import { RecentlyViewedItemsHeart_user$key } from './__generated__/RecentlyViewedItemsHeart_user.graphql';
import { RecentlyViewedItemsHeart_items$key } from './__generated__/RecentlyViewedItemsHeart_items.graphql';

const viewerFragment = graphql`
    fragment RecentlyViewedItemsHeart_viewer on Viewer
    @refetchable(queryName: "RecentlyViewedItemsHeartViewerRefetchQuery")
    @argumentDefinitions(
        loadPortfolioData: { type: "Boolean", defaultValue: false }
        selectedItemIds: { type: "[String]", defaultValue: [] }
        userIds: { type: "[String]", defaultValue: [] }
    ) {
        portfolioItemMatches(itemIds: $selectedItemIds, userIds: $userIds, portfolioTypes: [HEART])
            @include(if: $loadPortfolioData) {
            ...Heart_heartPortfolioItems
            item {
                serviceId
            }
        }
        ...Heart_viewer @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
    }
`;

const userFragment = graphql`
    fragment RecentlyViewedItemsHeart_user on User
    @argumentDefinitions(
        excludeItemPks: { type: "[String]", defaultValue: [] }
        count: { type: "Int", defaultValue: 0 }
    ) {
        ...useRecentHistoryList_user @arguments(excludeItemPks: $excludeItemPks, count: $count)
    }
`;

const itemsFragment = graphql`
    fragment RecentlyViewedItemsHeart_items on Item @relay(plural: true) {
        ...useRecentHistoryList_items
    }
`;

type FavoritesRefetch = (callback: (error: Error | null | undefined) => void) => void;

export const RecentlyViewedItemsHeart: FC<{
    currentItem: ItemType;
    authModalLoader: $TSFixMe;
    className?: string;
    buttonClass?: string;
    viewer: RecentlyViewedItemsHeart_viewer$key;
    user: RecentlyViewedItemsHeart_user$key | null | undefined;
    items: RecentlyViewedItemsHeart_items$key | null | undefined;
}> = ({
    currentItem,
    authModalLoader,
    className,
    buttonClass,
    viewer: viewerRef,
    user: userRef,
    items: itemsRefProp,
}) => {
    const user = useFragment(userFragment, userRef);
    const [viewer, refetch] = useRefetchableFragment(viewerFragment, viewerRef);
    const itemsRef = useFragment(itemsFragment, itemsRefProp);

    const { items } = useRecentHistoryList({ user, itemsRef });
    const { userId } = useRecentlyViewedItemsContext();
    const itemsIds = useMemo(() => items?.map(item => item.itemId), [items]);

    const doRefetch: FavoritesRefetch = useCallback(
        callback => {
            refetch(
                {
                    loadPortfolioData: true,
                    selectedItemIds: itemsIds,
                    userIds: userId ? [userId] : [],
                },
                {
                    onComplete: callback,
                    //Will prevent suspense on the whole carousel
                    fetchPolicy: 'store-and-network',
                }
            );
        },
        [refetch, itemsIds, userId]
    );

    const authModalShow = useCallback(
        (favoriteItem: unknown) => {
            authModalLoader
                ?.show({
                    flow: authModalLoader.constants.SAVE_ITEM_FLOW,
                })
                .then(favoriteItem);
        },
        [authModalLoader]
    );

    const { itemId } = currentItem;
    const itemMatch = useMemo(() => {
        const portfolioItemMatches = viewer.portfolioItemMatches || [];
        return portfolioItemMatches.filter(heart => heart?.item?.serviceId === itemId);
    }, [viewer.portfolioItemMatches, itemId]);

    return (
        <Heart
            theme="dark"
            itemId={itemId}
            viewer={viewer}
            userId={userId}
            heartPortfolioItems={itemMatch}
            doRefetch={doRefetch}
            authModalShow={authModalShow}
            className={className}
            buttonClass={buttonClass}
            onFavorited={() => {
                trackHeart({ action: 'added', itemId });
                addToEngagedItems(itemId);
            }}
            onUnFavorited={() => {
                trackHeart({ action: 'removed', itemId });
            }}
        />
    );
};
