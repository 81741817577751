import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { RecentHistoryItemType } from '../recentHistoryTypes';

import { useRecentHistoryList_user$key } from './__generated__/useRecentHistoryList_user.graphql';
import { useRecentHistoryList_items$key } from './__generated__/useRecentHistoryList_items.graphql';
import { getFormattedItemsReducer } from '../getFormattedItemsReducer';

const userFragment = graphql`
    fragment useRecentHistoryList_user on User
    @argumentDefinitions(
        excludeItemPks: { type: "[String]", defaultValue: [] }
        count: { type: "Int", defaultValue: 0 }
    ) {
        recentHistoryItems(excludeItemPks: $excludeItemPks, first: $count) {
            edges {
                node {
                    ...useRecentHistoryList_item @relay(mask: false)
                }
            }
        }
    }
`;

const itemsFragment = graphql`
    fragment useRecentHistoryList_items on Item @relay(plural: true) {
        ...useRecentHistoryList_item @relay(mask: false)
    }
`;

/**
 * This fragment is being spread on multiple fragments in this file.
 * It's useful to generate <fragmentName>$data type and avoid duplication.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonFragment = graphql`
    fragment useRecentHistoryList_item on Item {
        ...AuctionPricePaddle_item
        contemporaryTrackingString
        firstPhotoWebPath(size: small)
        pdpURL
        title
        browseUrl
        categoryCode
        serviceId
        categoryPath
        seller {
            serviceId
        }
    }
`;

type HookState = {
    items: RecentHistoryItemType[] | null;
    hasFetchedItems: boolean | null;
};

export const useRecentHistoryList = ({
    user: userRef,
    itemsRef,
}: {
    user: useRecentHistoryList_user$key | null | undefined;
    itemsRef: useRecentHistoryList_items$key | null | undefined;
}): HookState => {
    const user = useFragment(userFragment, userRef);
    const itemsData = useFragment(itemsFragment, itemsRef);

    const filteredLocalStorageItems = useMemo(() => {
        const fetchedItems = itemsData || [];
        return fetchedItems.reduce<RecentHistoryItemType[]>(getFormattedItemsReducer, []);
    }, [itemsData]);

    const filteredRecentHistoryItems = useMemo(() => {
        const fetchedItems = user?.recentHistoryItems?.edges || [];

        return fetchedItems.reduce<RecentHistoryItemType[]>((acc, edge) => {
            const item = edge?.node || null;
            return getFormattedItemsReducer(acc, item);
        }, []);
    }, [user]);

    return useMemo(
        () => ({
            items: [...filteredLocalStorageItems, ...filteredRecentHistoryItems],
            hasFetchedItems: !!filteredRecentHistoryItems.length,
        }),
        [filteredLocalStorageItems, filteredRecentHistoryItems]
    );
};
