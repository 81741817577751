import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
    graphql,
    useFragment,
    usePreloadedQuery,
    PreloadedQuery,
    useRefetchableFragment,
} from 'react-relay';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';
import LazyImage from 'dibs-react-lazy-image';
import {
    VisibilityTracker,
    VisibilityChangeCallback,
} from 'dibs-visibility-tracker/exports/VisibilityTracker';

import {
    recentlyViewedItemsQuery,
    useRecentlyViewedItemsContext,
} from './RecentlyViewedItemsContext';
import {
    RecentlyViewedItemsWrapper,
    RecentlyViewedItemsWrapperProps,
} from './RecentlyViewedItemsWrapper';
import { useRecentHistoryList } from './useRecentHistoryList';
import { RecentlyViewedItemsHeart } from './RecentlyViewedItemsHeart';
import { ACTION_LABEL, SRC_SET_SIZES_ITEM_TILE } from './constants';
import { trackItemImpression, trackItemClick, trackArrowClick } from '../recentHistoryTracking';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { AuctionPricePaddle } from 'dibs-auctions/exports/AuctionPricePaddle';
import { ScrollCarousel } from 'dibs-scroll-carousel/exports/ScrollCarousel';

import { RecentlyViewedItemsCarousel_viewer$key } from './__generated__/RecentlyViewedItemsCarousel_viewer.graphql';
import { RecentlyViewedItemsCarouselViewerRefetchQuery } from './__generated__/RecentlyViewedItemsCarouselViewerRefetchQuery.graphql';
import { RecentlyViewedItemsCarousel_user$key } from './__generated__/RecentlyViewedItemsCarousel_user.graphql';
import { RecentlyViewedItemsContextQuery } from './__generated__/RecentlyViewedItemsContextQuery.graphql';
import { ItemType } from '../recentHistoryTypes';

import styles from './RecentlyViewedItemsCarousel.scss';
import { RecentlyViewedItemsCarousel_items$key } from './__generated__/RecentlyViewedItemsCarousel_items.graphql';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

const viewerFragment = graphql`
    fragment RecentlyViewedItemsCarousel_viewer on Viewer
    @refetchable(queryName: "RecentlyViewedItemsCarouselViewerRefetchQuery")
    @argumentDefinitions(
        loadPortfolioData: { type: "Boolean", defaultValue: false }
        selectedItemIds: { type: "[String]", defaultValue: [] }
        userIds: { type: "[String]", defaultValue: [] }
    ) {
        ...RecentlyViewedItemsHeart_viewer
            @arguments(
                loadPortfolioData: $loadPortfolioData
                selectedItemIds: $selectedItemIds
                userIds: $userIds
            )
    }
`;

const userFragment = graphql`
    fragment RecentlyViewedItemsCarousel_user on User
    @argumentDefinitions(
        excludeItemPks: { type: "[String]", defaultValue: [] }
        count: { type: "Int", defaultValue: 0 }
    ) {
        ...useRecentHistoryList_user @arguments(excludeItemPks: $excludeItemPks, count: $count)
        ...RecentlyViewedItemsHeart_user @arguments(excludeItemPks: $excludeItemPks, count: $count)
    }
`;

const itemsFragment = graphql`
    fragment RecentlyViewedItemsCarousel_items on Item @relay(plural: true) {
        ...useRecentHistoryList_items
        ...RecentlyViewedItemsHeart_items
    }
`;

type OnInitialDisplay = ({ items }: { items: ItemType[] }) => void;

const Visibility: FC<{
    onInitialDisplay?: OnInitialDisplay;
    items: ItemType[];
    children: ReactNode;
}> = ({ onInitialDisplay, items, children }) => {
    const onVisibilityChange: (props: VisibilityChangeCallback) => void = useCallback(
        ({ isVisible, disconnect }) => {
            if (isVisible && onInitialDisplay && items?.length) {
                onInitialDisplay({ items });
                disconnect();
            }
        },
        [onInitialDisplay, items]
    );

    if (onInitialDisplay) {
        return (
            <VisibilityTracker onVisibilityChange={onVisibilityChange}>
                {children}
            </VisibilityTracker>
        );
    }

    return <>{children}</>;
};

export type RecentlyViewedItemsCarouselProps = {
    authModalLoader: $TSFixMe;
    queryReference: PreloadedQuery<RecentlyViewedItemsContextQuery>;
    trackItemClick?: typeof trackItemClick;
    trackItemImpression?: typeof trackItemImpression;
    onInitialDisplay?: OnInitialDisplay;
    itemsPerPage?: number;
    size?: 'xSmall' | 'small' | 'large';
    currency: string;
    isBot?: boolean;
} & Omit<RecentlyViewedItemsWrapperProps, 'children'>;

export const RecentlyViewedItemsCarousel: FC<RecentlyViewedItemsCarouselProps> = ({
    isMobile,
    centerTitle,
    alignTitle,
    wrapperClassName,
    authModalLoader,
    queryReference,
    actionLabel = ACTION_LABEL,
    trackViewMoreClick,
    onInitialDisplay,
    size = 'xSmall',
    currency,
    ...props
}) => {
    const data = usePreloadedQuery<RecentlyViewedItemsContextQuery>(
        recentlyViewedItemsQuery,
        queryReference
    );
    const user = useFragment<RecentlyViewedItemsCarousel_user$key>(
        userFragment,
        data.viewer?.user || null
    );
    const [viewer, refetch] = useRefetchableFragment<
        RecentlyViewedItemsCarouselViewerRefetchQuery,
        RecentlyViewedItemsCarousel_viewer$key
    >(viewerFragment, data.viewer);
    const itemsRef = useFragment<RecentlyViewedItemsCarousel_items$key>(
        itemsFragment,
        data?.viewer?.itemRead ? data.viewer.itemRead.filter(filterNulls) : null
    );

    const { items, hasFetchedItems } = useRecentHistoryList({ user, itemsRef });
    const { userId, ...context } = useRecentlyViewedItemsContext();

    /**
     * 'itemsPerPage' prop can be passed either to the RecentlyViewedItemsProvider, which populates the context,
     * or directly to the RecentlyViewedItemsCarousel component here. Default value if prop isn't passed to
     * either component is set by the provider using value of const CAROUSEL_RECENTLY_VIEWED_ITEMS_PER_PAGE
     * from recentHistoryConstants.ts
     */
    const itemsPerPage = props.itemsPerPage || context.itemsPerPage;
    const itemsIds = useMemo(() => items?.map(item => item.itemId), [items]);

    useEffect(() => {
        /**
         * Refetch favorites data if recent history contains fetched items
         */
        if (hasFetchedItems && itemsIds && userId) {
            refetch({
                loadPortfolioData: true,
                selectedItemIds: itemsIds,
                userIds: userId ? [userId] : [],
            });
        }
    }, [refetch, itemsIds, userId, hasFetchedItems]);

    if (!items?.length) {
        return null;
    }

    const itemArr = items.map((item, index) => (
        <div
            key={item.itemId}
            className={classnames(styles.itemWrapper, styles[size], isMobile && styles.swiperItem)}
        >
            <div className={classnames(styles.itemInner, styles[size])}>
                <RecentlyViewedItemsHeart
                    currentItem={item}
                    viewer={viewer}
                    user={user}
                    items={itemsRef}
                    authModalLoader={authModalLoader}
                    className={classnames(styles.heart, styles[size])}
                    buttonClass={classnames(styles.heartButton, styles[size])}
                />
                <Link
                    className={classnames(styles.imageLink, styles[size])}
                    href={item.itemPdpUrl}
                    dataTn="recently-viewed-item"
                    onClick={() => {
                        (props.trackItemClick || trackItemClick)({
                            item,
                            index,
                            actionLabel,
                        });
                    }}
                >
                    <LazyImage
                        className={classnames(styles.lazy, styles[size])}
                        placeholderClass={classnames(styles.lazy, styles[size])}
                        onContentVisible={() => {
                            (props.trackItemImpression || trackItemImpression)({
                                items: [item],
                                index,
                                actionLabel,
                            });
                        }}
                    >
                        <img
                            src={item.itemImageUrl}
                            className={styles.image}
                            alt={item.itemTitle}
                            sizes={SRC_SET_SIZES_ITEM_TILE}
                            srcSet={getSrcsetString(item.itemImageUrl)}
                        />
                    </LazyImage>
                </Link>

                <AuctionPricePaddle item={item} showPill currency={currency} />
            </div>
        </div>
    ));

    return (
        <Visibility onInitialDisplay={onInitialDisplay} items={items}>
            <RecentlyViewedItemsWrapper
                wrapperClassName={classnames(wrapperClassName, {
                    [styles.alignTitleLeft]: alignTitle === 'left',
                })}
                centerTitle={centerTitle}
                isMobile={isMobile}
                alignTitle={alignTitle}
                actionLabel={actionLabel}
                trackViewMoreClick={trackViewMoreClick}
            >
                <ScrollCarousel
                    animation="slide"
                    enableScroll
                    itemsToShow={itemsPerPage}
                    dataTn="recently-viewed-items-carousel"
                    {...(isMobile
                        ? {
                              stepSize: 1,
                          }
                        : {
                              stepSize: itemsPerPage,
                              isInfinite: true,
                              showArrows: true,
                              onArrowClick: ({ direction }) =>
                                  trackArrowClick({ direction, actionLabel }),
                          })}
                >
                    {itemArr}
                </ScrollCarousel>
            </RecentlyViewedItemsWrapper>
        </Visibility>
    );
};
